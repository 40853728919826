/* global $ */

// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
$(document).foundation();

var click = true;

$(document).ready(function() {

    $(".header--question").hide();
    $(".header--submit").hide();
    $(".header--info").hide();
    $(".progress__current").css("width", "0%");

});

$(".button__post").click(function(event) {
    event.preventDefault();
    confirmView();
})

$(".continue").click(function(event) {
    event.preventDefault();
    firstQuestion();
})

$("input[type='radio']").click(function(event) {
    $('html, body').css({
        'overflow': 'hidden',
        'height': '100%'
    });
    if (click) {
        click = false;
        var selected = this;
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            progress();
            var li = $(this).parents("li");
            if(li.children(".ink").length == 0) {
                li.prepend("<span class='ink'></span>");
            }
            var ink = li.children(".ink");
            ink.removeClass("animate");
            if(!ink.height() && !ink.width()) {
                var d = Math.max(li.outerWidth(), li.outerHeight());
                ink.css({height: d, width: d});
            }
            var x = event.pageX - li.offset().left - ink.width()/2;
            var y = event.pageY - li.offset().top - ink.height()/2;
            ink.css({top: y+'px', left: x+'px'}).addClass("animate");
            setTimeout(function() {
                nextQuestion(selected);
                ink.removeClass("animate");
                click = true;
                $('html, body').css({
                    'overflow': 'auto',
                    'height': 'auto'
                });
            }, 400);
        } else {
            progress();
            nextQuestion(selected);
            click = true;
            $('html, body').css({
                'overflow': 'auto',
                'height': 'auto'
            });
        }
    }
})

$(".nav__prev").click(function() {
    if ($(".header--submit").is(":visible")) {
        $(".header--question:last").show();
        $(".header--submit").hide();
        progressBack();
    } else if ($(".header--question:visible").prev(".header--question").length != 0) {
        $(".header--question:visible").prev().show().next().hide();
        progressBack();
    } else {
        $(".header--question:visible").hide();
        $(".header--main__frontpage").show();
    }
})

$('a[href^="#"]').on('click', function(event) {

    var target = $( $(this).attr('href') );

    if( target.length ) {
        event.preventDefault();
        $('html, body').animate({
            scrollTop: target.offset().top
        }, 1000);
    }
});

$(".main-nav--toggler").click(function() {
    $("#collapse--navbar").toggleClass("hide");
});

function confirmView() {
    var termsValid = false
    $('.checkbox--tietosuojaseloste--error').addClass('hidden')
    var tiet = $('#checkbox--tietosuojaseloste').prop('checked');
    if (!tiet) {
            $('.checkbox--tietosuojaseloste--error').removeClass('hidden')
    } else {
            termsValid = true
    }

    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var email = $("#id_email").val();
    if (!regex.test(email)) {
        $(".input__pre--radius").css("border-color", "#ff0000");
    } else if (regex.test(email) && termsValid) {
        window.scrollTo(0, 0);
        $(".header--main__frontpage").hide();
        $(".header--info").show();
    }
}

function firstQuestion() {
    $(".header--info").hide();
    $(".header--question:first").show();
}

function nextQuestion(selected) {
    if ($(".header--question:visible").next(".header--question").length != 0) {
        changeColor(selected);
        $(".header--question:visible").next().show().prev().hide();
    } else {
        changeColor(selected);
        $(".header--question:visible").hide();
        $(".header--submit").show();
    }
}

function changeColor(selected) {
    // Because of Safari .find() can't be used so use children().children()
    $(selected).parents("ul.answer__list").children().children("a.answer__button").removeClass("selected");
    $(selected).parents("a.answer__button").addClass("selected");
}

function progress() {
    var now = $(".progress__current").get(0).style.width;
    now = parseFloat(now);
    setTimeout(function() {
        $(".progress__current").css("width", now + 3.125 + "%" );
    }, 50);
}

function progressBack() {
    var now = $(".progress__current").get(0).style.width;
    now = parseFloat(now);
    setTimeout(function() {
        $(".progress__current").css("width", now - 3.125 + "%" );
    }, 50);
}

function windowFeatures(width, height) {
    var leftPosition, topPosition;
    leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeaturesStr = 'status=no,height=' + height + ',width=' + width +
        ',resizable=no,left=' + leftPosition + ',top=' + topPosition +
        ',screenX=' + leftPosition + ',screenY=' + topPosition +
        ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no';
    return windowFeaturesStr;
}
window.windowFeatures = windowFeatures;
